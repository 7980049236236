import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { finalize, map } from 'rxjs/operators';
import { SetupService } from 'src/app/users/service/operations/setup.service';
import { ShiftSchedulerService } from 'src/app/users/service/operations/shift-scheduler.service';

@Component({
  selector: 'app-view-shift',
  templateUrl: './view-shift.component.html',
  styleUrls: ['./view-shift.component.scss'],
})
export class ViewShiftComponent implements OnInit {
  @Output() bu = new EventEmitter<any>();
  @ViewChild('sidemenu') sidemenu: ElementRef;
  isView: boolean;
  activeId = 1;
  routeSub;
  buOption: any;
  planShift: any;
  receivedPlanShift: any;
  buId: any;
  selectedWeek: any;
  weeksArr = [];
  headerData: any;
  pagefrom: string;
  topData: any;
  startDateLocal: Date;
  weeksLoader: boolean;
  buLoader: boolean = true;
  buForShift: any[];
  shiftsPageData: any = null;
  isShiftsPageActive: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private planShiftService: ShiftSchedulerService,
    private setupService: SetupService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.onSameUrlNavigation = 'reload';
    this.routeSub = this.route.data.subscribe((val) => {
      this.isView = val.isView;
    });
  }

  ngOnInit(): void {
    this.getAllBuForShift();
  }

  getAllBuForShift() {
    this.buLoader = true;
    const buSubscribe = this.setupService
      .getAllBuShiftModule()
      .pipe(
        finalize(() => {
          this.buLoader = false;
          buSubscribe.unsubscribe();
        })
      )
      .subscribe(
        (res: any) => {
          //if (res.success) {
            this.buForShift = res.data.businessUnitList;
         /* } else {
            this.buForShift = [];
          }*/
        },
        (error) => {
          this.buForShift = [];
        }
      );
  }

  ngAfterViewInit() {
    if (!this.isView) {
      setTimeout(() => {
        this.renderer.addClass(this.sidemenu.nativeElement, 'animate');
      }, 50);
    }
  }

  ngOnDestroy(): void {
    this.routeSub.unsubscribe();
  }

  onBuChange() {
    this.weeksLoader = true;
    const buWeeks = this.planShiftService
      .getWeeks(this.buId._id)
      .pipe(
        finalize(() => {
          this.weeksLoader = false;
          buWeeks.unsubscribe();
        })
      )
      .subscribe((res) => {
        //this.weeksArr
        const weekCount = res['data'];
        //this.selectedWeek
        const number = res['data'].noOfWeek;
        let getDate = moment().startOf('isoWeek').format("MM-DD-YYYY");
        if (number > 0) {
          for (var i = -number; i <= number; i++) {
            let dummyData = this.getWeekStartEndDate(getDate, 7 * (i))
           this.weeksArr.push(dummyData);
            //localStorageService.set("WorkWeekList",  $scope.myWeekRange);
          }
          /*this.planOpenshiftForm.patchValue({
            selectWeek: this.weekArr1[res['data'].noOfWeek],
          });*/
          this.selectedWeek = this.weeksArr[number]?.label;
          console.log("this.weeksArr",weekCount,number,this.selectedWeek);
        }

      });
  }

  getWeekStartEndDate = function (date, count) {
    let datesToReturn = {};
    let label = moment().startOf('isoWeek').add(count, 'days').format("MMM DD YYYY") + ' to ' + moment().startOf('isoWeek').add(count + 6, 'days').format("MMM DD YYYY")
    let value = moment().startOf('isoWeek').add(count, 'days').format("MM-DD-YYYY");

    datesToReturn = { label: label, value: value, key: count };
   /* if (count == 0 && $scope.workWeeksDate == undefined) {

      $scope.workWeeksDate = value;

    }*/
    return datesToReturn;
  }

  changeToDate(d, n) {
    var months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    var sd = d.slice(4, 6).toString();
    var dd = parseInt(sd);
    var smm = d.slice(0, 3);
    var mm = months.indexOf(smm);
    var yy = parseInt(d.slice(7, 11));
    var startDate = new Date(yy, mm, dd);
    this.startDateLocal = new Date(yy, mm, dd);

    if (n == 0) {
      var startDate = new Date(yy, mm, dd);
      return startDate;
    } else if (n == 1) {
      var endDate = new Date(startDate.setDate(startDate.getDate() + 6));
      return endDate;
    }
  }

  dateFormat2(d) {
    var dd = d.getDate().toString();
    var mm = (d.getMonth() + 1).toString();
    var yy = d.getFullYear().toString();

    var date =
      mm.padStart(2, '0') +
      '-' +
      dd.padStart(2, '0') +
      '-' +
      yy +
      ' 00:00:00 GMT+0530';
    console.log('dd', date);

    return date;
  }

  viewClicked() {
    this.bu.emit(this.buId);
    this.isShiftsPageActive = false;
    this.planShift = {
      businessUnitId: this.buId?._id,
      startDate: this.dateFormat2(this.changeToDate(this.selectedWeek, 0)),
      from: 'plan',
    };

    this.planShiftService.getShiftGraph(this.planShift).subscribe((res) => {
      this.receivedPlanShift = res['data'];
      this.isView = true;
      this.headerData = {
        one: this.receivedPlanShift.dashboardGraphData.plannedFlexiHours,
        two: this.receivedPlanShift.dashboardGraphData.plannedFlexiShifts,
        three: this.receivedPlanShift.dashboardGraphData.bookedFlexiHours,
        four: this.receivedPlanShift.dashboardGraphData.bookedFlexiShifts,
        five: this.receivedPlanShift.dashboardGraphData.assignFlexiHours,
        six: this.receivedPlanShift.dashboardGraphData.assignFlexiShifts,
      };
      console.log('View Shift Read', res);

      this.headerHrsStaff({ count: 0, day: 'M' });
    });

    this.pagefrom = 'view';
  }

  //Refresh Header data on Overview Click
  refreshHeaderData() {
    this.headerData = {
      one: 0,
      two: 0,
      three: 0,
      four: 0,
      five: 0,
      six: 0,
    };
    this.planShift = {
      businessUnitId: this.buId?._id,
      startDate: this.dateFormat2(this.changeToDate(this.selectedWeek, 0)),
      from: 'plan',
      date: this.dateFormat2(this.changeToDate(this.selectedWeek, 0)),
    };

    this.planShiftService.getPlanShift(this.planShift).subscribe((res) => {
      this.receivedPlanShift = res['data'];
      this.headerData = {
        one: this.receivedPlanShift.dashboardGraphData.plannedFlexiHours,
        two: this.receivedPlanShift.dashboardGraphData.plannedFlexiShifts,
        three: this.receivedPlanShift.dashboardGraphData.bookedFlexiHours,
        four: this.receivedPlanShift.dashboardGraphData.bookedFlexiShifts,
        five: this.receivedPlanShift.dashboardGraphData.assignFlexiHours,
        six: this.receivedPlanShift.dashboardGraphData.assignFlexiShifts,
      };

    });
  }

  getheaderData(e) {
    this.headerData = {
      one: e.plannedHr,
      two: e.plannedStaff,
      three: e.bookedHr,
      four: e.bookedStaff,
    };
  }

  headerHrsStaff(e) {
    const [start, end] = this.selectedWeek.split(' to ');
    const weekStart = moment(start, 'MMM DD YYYY');
    const weekEnd = moment(end, 'MMM DD YYYY');
    const weekdays = [];
    moment.updateLocale('en', {
      week: {
        dow: 1, // Monday is the first day of the week.
      },
    });
    for (
      let day = weekStart.clone();
      day.isSameOrBefore(weekEnd);
      day.add(1, 'day')
    ) {
      weekdays.push(day.format('DD-MM-YYYY'));
    }
    this.topData = this.shiftsPageData?.graphDataWeb[weekdays[e.count]];
  }

  switchToShift () {
    this.planShiftService
      .getPlanShift(this.planShift)
      .pipe(finalize(() => {}))
      .subscribe(
        (res: any) => {
          this.shiftsPageData = res['data'];
          this.isShiftsPageActive = true;
          this.headerHrsStaff({ count: 0, day: 'M' });
        },
        (error: any) => {
          console.error('Failed to Refresh Data', 'Error');
        }
      );
  }
}
