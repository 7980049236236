import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ElementRef,
  ViewChild,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment';
import { finalize, map } from 'rxjs/operators';
import { SetupService } from 'src/app/users/service/operations/setup.service';
import { ShiftSchedulerService } from 'src/app/users/service/operations/shift-scheduler.service';

@Component({
  selector: 'app-view-booking',
  templateUrl: './view-booking.component.html',
  styleUrls: ['./view-booking.component.scss'],
})
export class ViewBookingComponent implements OnInit {
  @Output() bu = new EventEmitter<any>();
  @Output() startDate = new EventEmitter<any>();
  @ViewChild('sidemenu') sidemenu: ElementRef;
  isView: boolean;
  activeId = 1;
  planShift: any;
  buId: any;
  routeSub;
  receivedPlanShift: any;
  buOption: any;
  selectedWeek: any;
  weeksArr = [];
  headerData: any;
  planShiftpara: any;
  shiftReadRes: any;
  topData: any;
  startDateLocal: Date;
  weeksLoader: boolean;
  buLoader: boolean = true;
  buForShift: any[];
  isLoader: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private planShiftService: ShiftSchedulerService,
    private setupService: SetupService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.onSameUrlNavigation = 'reload';
    this.routeSub = route.data.subscribe((val) => {
      this.isView = val.isView;
    });
  }

  ngOnInit(): void {
    this.getAllBuForShift();
  }

  getAllBuForShift() {
    this.buLoader = true;
    const buSubscribe = this.setupService
      .getAllBuShiftModule()
      .pipe(
        finalize(() => {
          this.buLoader = false;
          buSubscribe.unsubscribe();
        })
      )
      .subscribe(
        (res: any) => {
          //if (res.success) {
            this.buForShift = res.data.businessUnitList;
          /*} else {
            this.buForShift = [];
          }*/
        },
        (error) => {
          this.buForShift = [];
        }
      );
  }

  ngAfterViewInit() {
    if (!this.isView) {
      setTimeout(() => {
        this.renderer.addClass(this.sidemenu.nativeElement, 'animate');
      }, 50);
    }
  }

  onBuChange() {
    this.weeksLoader = true;
    const buWeeks = this.planShiftService
      .getWeeks(this.buId._id)
      .pipe(
        finalize(() => {
          this.weeksLoader = false;
          buWeeks.unsubscribe();
        })
      )
      .subscribe((res) => {
        //this.weeksArr = res['data']['data'];
        console.log(this.weeksArr[res['data'].noOfWeek])
        this.selectedWeek = this.weeksArr[res['data'].noOfWeek];
        let getDate = moment().startOf('isoWeek').format("MM-DD-YYYY");
        const count = res['data'].noOfWeek
        //this.currentWeekCount = count;
        if (count > 0) {
          for (var i = -count; i <= count; i++) {
            let dummyData = this.getWeekStartEndDate(getDate, 7 * (i))
            this.weeksArr.push(dummyData);
            //localStorageService.set("WorkWeekList",  $scope.myWeekRange);
          }
          this.selectedWeek = this.weeksArr[count]?.label;
        }
      });
  }


  getWeekStartEndDate = function (date, count) {
    let datesToReturn = {};
    let label = moment().startOf('isoWeek').add(count, 'days').format("MMM DD YYYY") + ' to ' + moment().startOf('isoWeek').add(count + 6, 'days').format("MMM DD YYYY")
    let value = moment().startOf('isoWeek').add(count, 'days').format("MM-DD-YYYY");

    datesToReturn = { label: label, value: value, key: count };
   /* if (count == 0 && $scope.workWeeksDate == undefined) {

      $scope.workWeeksDate = value;

    }*/
    return datesToReturn;
  }

  changeToDate(d, n) {
    var months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    var sd = d?.slice(4, 6).toString();
    var dd = parseInt(sd);
    var smm = d?.slice(0, 3);
    var mm = months.indexOf(smm);
    var yy = parseInt(d.slice(7, 11));
    this.startDateLocal = new Date(yy, mm, dd);

    if (n == 0) {
      var startDate = new Date(yy, mm, dd);
      return startDate;
    } else if (n == 1) {
      var endDate = new Date(startDate.setDate(startDate.getDate() + 6));
      return endDate;
    }
  }

  dateFormat2(d) {
    var dd = d.getDate().toString();
    var mm = (d.getMonth() + 1).toString();
    var yy = d.getFullYear().toString();

    var date =
      mm.padStart(2, '0') +
      '-' +
      dd.padStart(2, '0') +
      '-' +
      yy +
      ' 00:00:00 GMT+0530';
    return date;
  }

  viewClicked() {
    this.startDate.emit(this.startDateLocal);
    this.bu.emit(this.buId);
    this.headerData = {
      one: 0,
      two: 0,
      three: 0,
      four: 0,
    };

    this.isView = true;
    this.planShiftpara = {
      businessUnitId: this.buId._id,
      startDate: this.dateFormat2(this.changeToDate(this.selectedWeek, 0)),
      from: 'plan',
    };

    this.planShiftService.getShiftGraph(this.planShiftpara).subscribe((res) => {
      this.shiftReadRes = res['data'];
      this.headerData = {
        one: res['data']['dashboardGraphData'].plannedFlexiHours,
        two: res['data']['dashboardGraphData'].plannedFlexiShifts,
        three: res['data']['dashboardGraphData'].bookedFlexiHours,
        four: res['data']['dashboardGraphData'].bookedFlexiShifts,
      };
      this.headerHrsStaff({ count: 0, day: 'M' });
      this.isLoader = false;
    });
  }

  refreshHeader() {
    this.isLoader = true;
    this.headerData = {
      one: 0,
      two: 0,
      three: 0,
      four: 0,
    };
  
    const startDate = this.dateFormat2(this.changeToDate(this.selectedWeek, 0));
    
    this.planShiftpara = {
      businessUnitId: this.buId._id,
      startDate: startDate,
      from: 'plan'
    };
  
    this.planShiftService.getShiftGraph(this.planShiftpara).subscribe(
      (res) => {
        const { plannedFlexiHours, plannedFlexiShifts, bookedFlexiHours, bookedFlexiShifts } = res['data']['dashboardGraphData'];
        this.headerData = {
          one: plannedFlexiHours,
          two: plannedFlexiShifts,
          three: bookedFlexiHours,
          four: bookedFlexiShifts,
        };
        this.headerHrsStaff({ count: 0, day: 'M' });
        this.isLoader = false;
      },
      (error) => {
        console.error(error);
        this.isLoader = false;
      }
    );
  }
  

  getheaderData(e) {
    this.headerData = {
      one: e.plannedHr,
      two: e.plannedStaff,
    };
  }

  headerHrsStaff(e) {
    const [start, end] = this.selectedWeek.split(' to ');
    const weekStart = moment(start, 'MMM DD YYYY');
    const weekEnd = moment(end, 'MMM DD YYYY');
    const weekdays = [];
    moment.updateLocale('en', {
      week: {
        dow: 1, // Monday is the first day of the week.
      },
    });
    for (
      let day = weekStart.clone();
      day.isSameOrBefore(weekEnd);
      day.add(1, 'day')
    ) {
      weekdays.push(day.format('DD-MM-YYYY'));
    }
    this.topData = this.shiftReadRes['graphDataWeb'][weekdays[e.count]];
  }

  switchToBooking() {
    this.isLoader = true;
  
    const startDate = this.dateFormat2(this.changeToDate(this.selectedWeek, 0));
    
    this.planShift = {
      businessUnitId: this.buId._id,
      cancelledShifts: true,
      startDate: startDate,
    };
    
    this.planShiftService.getViewBooking(this.planShift).subscribe(
      (res) => {
        this.receivedPlanShift = res;
        this.isLoader = false;
      },
      (error) => {
        console.error(error);
        this.isLoader = false;
      }
    );
  }
  
}
