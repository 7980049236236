import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { SetupService } from 'src/app/users/service/operations/setup.service';
import { ShiftSchedulerService } from 'src/app/users/service/operations/shift-scheduler.service';
import { ManageComponent } from './manage/manage.component';

@Component({
  selector: 'app-manage-shift',
  templateUrl: './manage-shift.component.html',
  styleUrls: ['./manage-shift.component.scss'],
})
export class ManageShiftComponent implements OnInit {
  @ViewChild('sidemenu') sidemenu: ElementRef;
  isView: boolean;
  activeId = 1;
  routeSub;
  buOption: any;
  planShift: any;
  receivedPlanShift: any;
  buId: any;
  selectedWeek: any;
  weeksArr = [];
  weeksLoader: boolean;
  headerData: any;
  startDate: any;
  topData: any;
  managePageData: any = null;
  isManageTabActive: boolean = false;
  refreshStatus: boolean = false;

  @ViewChild(ManageComponent)
  private myChild: ManageComponent;

  eventsSubject: Subject<void> = new Subject<void>();
  startDateLocal: Date;
  refreshNum: number = 0;
  buLoader: boolean = true;
  buForShift: any;
  isLoader : boolean = false

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private planShiftService: ShiftSchedulerService,
    private setupService: SetupService,
    private toastr: ToastrService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.onSameUrlNavigation = 'reload';
    this.routeSub = route.data.subscribe((val) => {
      this.isView = val.isView;
    });
  }

  ngOnInit(): void {
    this.getAllBuForShift();
  }

  getAllBuForShift() {
    this.buLoader = true;
    const buSubscribe = this.setupService
      .getAllBuShiftModule()
      .pipe(
        finalize(() => {
          this.buLoader = false;
          buSubscribe.unsubscribe();
        })
      )
      .subscribe(
        (res: any) => {
         // if (res.success) {
            this.buForShift = res.data.businessUnitList;
          /*} else {
            this.buForShift = [];
          }*/
        },
        (error) => {
          this.buForShift = [];
        }
      );
  }

  ngAfterViewInit() {
    if (!this.isView) {
      setTimeout(() => {
        this.renderer.addClass(this.sidemenu.nativeElement, 'animate');
      }, 50);
    }
  }

  ngOnDestroy(): void {
    this.routeSub.unsubscribe();
  }

  refreshPage(id) {
    this.activeId = id;
    this.refreshNum++;
    switch (id) {
      case 1:
        this.viewClicked();
        break;
      case 2:
        this.switchToManage();
        break;
      case 3:
        this.refreshStatus = !this.refreshStatus;;
        break;
      default:
        break;
    }

    this.toastr.success('Data Refreshed Successfully', 'Refreshed');
  }

  onBuChange() {
    this.weeksLoader = true;
    const buWeeks = this.planShiftService
      .getWeeks(this.buId._id)
      .pipe(
        finalize(() => {
          this.weeksLoader = false;
          buWeeks.unsubscribe();
        })
      )
      .subscribe((res) => {
       // this.weeksArr = res['data']['data'];
        //this.selectedWeek
        const count = res['data'].noOfWeek == 0 ? 1:res['data'].noOfWeek;
        let getDate = moment().startOf('isoWeek').format("MM-DD-YYYY");
        if (count > 0) {
          for (var i = -count; i <= count; i++) {
            let dummyData = this.getWeekStartEndDate(getDate, 7 * (i))
           this.weeksArr.push(dummyData);
            //localStorageService.set("WorkWeekList",  $scope.myWeekRange);
          }
        }
        this.selectedWeek = this.weeksArr[count]?.label;
      });
  }

  getWeekStartEndDate = function (date, count) {
    let datesToReturn = {};
    let label = moment().startOf('isoWeek').add(count, 'days').format("MMM DD YYYY") + ' to ' + moment().startOf('isoWeek').add(count + 6, 'days').format("MMM DD YYYY")
    let value = moment().startOf('isoWeek').add(count, 'days').format("MM-DD-YYYY");

    datesToReturn = { label: label, value: value, key: count };
   /* if (count == 0 && $scope.workWeeksDate == undefined) {

      $scope.workWeeksDate = value;

    }*/
    return datesToReturn;
  }

  changeToDate(d, n) {
    var months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    var sd = d.slice(4, 6).toString();
    var dd = parseInt(sd);
    var smm = d.slice(0, 3);
    var mm = months.indexOf(smm);
    var yy = parseInt(d.slice(7, 11));
    this.startDateLocal = new Date(yy, mm, dd);

    if (n == 0) {
      var startDate = new Date(yy, mm, dd);
      return startDate;
    } else if (n == 1) {
      var endDate = new Date(startDate.setDate(startDate.getDate() + 6));
      return endDate;
    }
  }

  viewClicked() {
    this.isLoader = true
    this.isManageTabActive = false;
    this.startDate = moment(this.changeToDate(this.selectedWeek, 0)).format(
      'MM-DD-YYYY [00:00:00 GMT]ZZ'
    );
    this.planShift = {
      businessUnitId: this.buId._id,
      startDate: moment(this.changeToDate(this.selectedWeek, 0)).format(
        'MM-DD-YYYY [00:00:00 GMT]ZZ'
      ),
      from: 'plan',
    };

    this.planShiftService.getShiftGraph(this.planShift).subscribe((res) => {
      this.receivedPlanShift = res['data'];
      this.isView = true;
      this.headerData = {
        one: this.receivedPlanShift.dashboardGraphData.plannedFlexiHours,
        two: this.receivedPlanShift.dashboardGraphData.plannedFlexiShifts,
        three: this.receivedPlanShift.dashboardGraphData.bookedFlexiHours,
        four: this.receivedPlanShift.dashboardGraphData.bookedFlexiShifts,
        five: this.receivedPlanShift.dashboardGraphData.assignFlexiHours,
        six: this.receivedPlanShift.dashboardGraphData.assignFlexiShifts,
      };

      this.headerHrsStaff({ count: 0, day: 'M' });
      this.isLoader = false
    });
  }

  headerHrsStaff(e) {
    const [start, end] = this.selectedWeek.split(' to ');
    const weekStart = moment(start, 'MMM DD YYYY');
    const weekEnd = moment(end, 'MMM DD YYYY');
    const weekdays = [];
    moment.updateLocale('en', {
      week: {
        dow: 1, // Monday is the first day of the week.
      },
    });
    for (
      let day = weekStart.clone();
      day.isSameOrBefore(weekEnd);
      day.add(1, 'day')
    ) {
      weekdays.push(day.format('DD-MM-YYYY'));
    }
    this.topData = this.managePageData?.graphDataWeb[weekdays[e.count]];
  }

  changeActiveTab($event) {
    this.activeId = $event;
  }

  refreshHeaderData() {
    this.headerData = {
      one: 0,
      two: 0,
      three: 0,
      four: 0,
    };
    this.planShift = {
      businessUnitId: this.buId,
      startDate: moment(this.changeToDate(this.selectedWeek, 0)).format(
        'MM-DD-YYYY [00:00:00 GMT]ZZ'
      ),
      from: 'plan',
      date: moment(this.changeToDate(this.selectedWeek, 0)).format(
        'MM-DD-YYYY [00:00:00 GMT]ZZ'
      ),
      cancelledShifts: true,
    };

    this.planShiftService.getPlanShift(this.planShift).subscribe((res) => {
      this.receivedPlanShift = res['data'];
      this.headerData = {
        one: this.receivedPlanShift.dashboardGraphData.plannedFlexiHours,
        two: this.receivedPlanShift.dashboardGraphData.plannedFlexiShifts,
        three: this.receivedPlanShift.dashboardGraphData.bookedFlexiHours,
        four: this.receivedPlanShift.dashboardGraphData.bookedFlexiShifts,
        five: this.receivedPlanShift.dashboardGraphData.assignFlexiHours,
        six: this.receivedPlanShift.dashboardGraphData.assignFlexiShifts,
      };
    });
  }

  switchToManage() {
    this.isLoader = true;
    this.planShiftService
      .getPlanShift(this.planShift)
      .pipe(
        finalize(() => {
          this.isLoader = false;
        })
      )
      .subscribe(
        (res: any) => {
          this.managePageData = res['data'];
          if (!this.isManageTabActive) {           
            this.isManageTabActive = true;
          }
          this.headerHrsStaff({ count: 0, day: 'M' });
        },
        (error: any) => {
          this.toastr.error('Failed to Refresh Data', 'Error');
        }
      );
  }
}
