import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Injectable,
  OnChanges,
} from '@angular/core';

import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';

import { FormBuilder, FormGroup, FormArray } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { SetupService } from 'src/app/users/service/operations/setup.service';
import { ShiftSchedulerService } from 'src/app/users/service/operations/shift-scheduler.service';
import { CompanySetupService } from 'src/app/users/service/company-setup/company-setup.service';
import { finalize } from 'rxjs/operators';
import { getGmtOffSet } from 'src/app/core/utils/helper';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '-';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);

      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1].padStart(2, '0'), 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? date.day +
      this.DELIMITER +
      parseInt(date.month.toString().padStart(2, '0'), 10) +
      this.DELIMITER +
      date.year
      : '';
  }
}

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class ManageComponent implements OnInit, OnChanges {
  @Output() ChangeTabId = new EventEmitter<any>();
  @Output() headerData = new EventEmitter<any>();
  @Output() day = new EventEmitter<any>();
  @Input() shift;
  @Input() week;
  @Input() buId;
  @Input() page;
  @Input() refresh: any;
  @Input() isLoader : boolean;
  cancel: boolean = false;
  isErrorDisplay = false

  dayArr = [
    { count: 0, day: 'M' },
    { count: 1, day: 'T' },
    { count: 2, day: 'W' },
    { count: 3, day: 'T' },
    { count: 4, day: 'F' },
    { count: 5, day: 'S' },
    { count: 6, day: 'S' },
  ];

  timeDropDown: any[];
  weekDates = [];
  skillLabel = [];
  selectedDay: any;
  dayShifts: any;
  ShiftForm: FormGroup;
  extArr: FormArray;
  selectedShift: any;
  plannedHr: number;
  plannedStaff: number;
  headerDataArr: any;
  hideBtn: boolean = true;
  disabled = false;
  username: any;
  showshift: boolean = false;
  days: any;
  result: any;
  confirmedShiftForm: any;
  confirmedUserId: any;
  toggle = true;
  status = 'Enable';
  adjustShiftForm: any;
  cancelShiftForm: any;
  businessUnitId: any;
  shiftDetailsId: any;
  startDate: string;
  staffNeedCount: any;
  adjustShiftDetId: any;
  weekstartDay: string;
  confirmedYesShiftForm: any;
  userId: any;
  startTimeDay: string;
  endTimeDay: string;
  startdays: any;
  enddays: any;
  from: string;
  alert: any;
  startDateTime: string;
  endDateTime: string;
  message: void;
  sendRequestBtn: string;
  extendedStaffTime: any;
  extendedStaffTimeD: string;
  rest: any;
  off: any;
  requestShiftForm: any;
  stopRequestShiftForm: any;
  buRepoLocation: any;
  shiftReqId: any;
  reqEnddate: any;
  reqstartdate: any;
  showRequest: boolean = false;
  showStopRequest: boolean = false;
  cancelPermission: boolean = true;
  showRequestShift: string;
  stopShiftId: any;
  stopShiftDetailsId: any;
  showCancelDiv: boolean = false;
  hideDivForAssign: boolean = true;
  currentDate: any;
  cancelDays: string;
  errors: any;
  hideDivForOpen: boolean = true;
  isShiftReqChangeObj: any;
  requestShift: boolean;
  reqShiftForm: FormGroup;
  bookedHr: number;
  bookedStaff: number;
  ShiftDetIdAdjust: any;
  isRequested: any;
  shiftItem: any;
  extentionStaff: any;
  extShift = [];
  extItem: FormArray;
  cfr: boolean;
  restOffForm: FormGroup;
  recallForm: FormGroup;
  isSplit: boolean = false;
  reportLocation: any;
  skillSet = [];
  subSkill = [];
  skillsLabel = [];
  skillsId = [];
  shiftRecallReqPayload: any;
  selectedRecall: any;
  isSplitTime: any;
  tier: number;
  buShiftDetails: any;
  cancelShiftbtn: boolean;
  adjustShiftbtn: boolean = false;
  hideAllButton: boolean;
  cancelBookedShiftButton:boolean;
  today = new Date();
  minDate = { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() };
  maxDate = { year: new Date().getFullYear() + 1, month: 12, day: 31 };
  gmtOffset: any;
  hideCancelShifts: boolean = false;
  selectedShiftStatus: number;
  constructor(
    private fb: FormBuilder,
    private calendar: NgbCalendar,
    public toastService: ToastrService,
    private planShiftService: ShiftSchedulerService,
    private buService: SetupService,
    private companyService: CompanySetupService
  ) { }

  ngOnInit(): void {
    this.gmtOffset = getGmtOffSet();

    this.getBuShift(this.buId);
    this.selectedDay = this.dayArr[0];
    this.ShiftForm = this.fb.group({
      buName: [''],
      date: [''],
      startTime: [''],
      endTime: [''],
      reqStartTime: [''],
      startDateTime: [''],
      splitstartTime: [''],
      splitendTime: [''],
      endDateTime: [''],
      extArr: this.fb.array([]),
      staffNeedCount: [''],
      backUpStaffNeedCount: [''],
      backUpStaffNeedCountBooked: [''],
      backUpStaffNeedCountSBBooked: [''],
      setTimingStart: [''],
      confirmedCount: [''],
      standByCount: [''],
      adjust: [''],
      reqEndTime: [''],
      reportLocationId: [''],
      reqReportLocationId: [''],
      reqStaffNeedCount: [''],
    });

    this.createDays();
    this.getSelectedWeek1(this.selectedDay);
    this.getPage();
    this.displayLocationTable();
    this.selectTime();
    this.getTier();
  }

  getBuShift(bu) {
    this.buService.getBuShiftEdit(bu._id).subscribe((res: { result }) => {
      this.buShiftDetails = res['data']['businessUnitList'];
    });
  }

  getTier() {
    this.companyService.getcompanySetting().subscribe((res) => {
      this.tier = res.data.opsGroup?.tierType;
    });
  }

  ngOnChanges() {
    if (this.refresh) {
      this.selectedDay = this.dayArr[0];
      this.createDays();
      this.getSelectedWeek1(this.selectedDay);
      this.getPage();
    }
  }

  changeStatus(event): void {
    this.hideCancelShifts = event?.target?.checked;
   if (!!event?.target?.checked && this.selectedShiftStatus == 2) {
    this.selectedShift = null;
    this.ShiftForm?.reset();
   }
}

  selectTime() {
    this.timeDropDown = [];
    for (let hour = 0; hour < 24; hour++) {
      this.timeDropDown.push(moment({ hour }).format('HH:mm'));
      this.timeDropDown.push(moment({ hour, minute: 15 }).format('HH:mm'));
      this.timeDropDown.push(moment({ hour, minute: 30 }).format('HH:mm'));
      this.timeDropDown.push(moment({ hour, minute: 45 }).format('HH:mm'));
    }
  }

  displayLocationTable() {
    this.buService.getRepoLocations(this.buId).subscribe((res) => {
      this.buRepoLocation = res['data'].reportingLocation;
    });
  }

  cancelShift(val) {
    this.cancel = val;
  }

  toggleshift(i) {

    this.confirmedUserId = i._id;
    this.showshift = !this.showshift;

    this.ShiftForm?.patchValue({
      startDateTime: i.startTime,
      endDateTime: i.endTime,
    });
  }

  getPage() {
    if (this.page == 'view') {
      this.hideBtn = false;
    }
  }

  createDays() {
    for (var i = 0; i < 7; i++) {
      var j = moment(this.week);
      this.weekDates.push(j.add(i, 'days').format('DD-MM-YYYY'));
    }
  }

  getSelectedWeek1(day) {
    this.day.emit(day);
    this.selectedDay = day;
    for (var i = 0; i < 7; i++) {
      if (this.selectedDay.count == i) {
        const shiftKeyList = Object.keys(this.shift.list);
        if (shiftKeyList.length) {
          if (shiftKeyList.includes(this.weekDates[i])) {
            this.dayShifts = this.shift.list[this.weekDates[i]];
            this.getListShift(this.dayShifts[0]);
          } else {
            this.dayShifts = [];
          }
        } else {
          this.dayShifts = [];
          this.skillLabel = [];
          this.ShiftForm?.reset();
        }

        for (let m = 0; this.dayShifts.length > m; m++) {
          if (this.dayShifts[m].isRest == true) {
            this.rest = this.dayShifts[m].confirmedStaffs;
          }
        }
        for (let k = 0; this.dayShifts.length > k; k++) {
          if (this.dayShifts[k].isOff == true) {
            this.off = this.dayShifts[k].confirmedStaffs;
          }
        }
      }
    }
  }

  getListShift(i) {
    this.selectedShiftStatus = i?.status;
    this.cfr = false;
    this.shiftItem = i;
    var d = new Date();
    this.currentDate = d.toISOString();
    this.selectedShift = i;
    this.skillLabel = [];
    this.cancelPermission = i.shiftId.businessUnitId.cancelShiftPermission;
    this.reqstartdate = moment(this.shiftItem.startTime).format('MM-DD-YYYY');
    this.reqEnddate = moment(this.shiftItem.endTime).format('MM-DD-YYYY');
    this.shiftItem._id = i._id;
    this.shiftReqId = i.shiftId._id;
    this.username = i.confirmedStaffs;
    this.extendedStaffTime = i.extendedStaff;
    this.weekstartDay =
      moment(i.shiftId.weekRangeStartsAt).format('MM-DD-YYYY') +
      ' 00:00:00 '+ this.gmtOffset;
    this.days = moment(i.startTime).format('MM-DD-YYYY') + ' 00:00:00 '+ this.gmtOffset;
    this.cancelDays = i.startTime;

    if (this.cancelDays >= this.currentDate) {
      this.showCancelDiv = true;
    }

    if (i.isAssignShift == true) {
      this.hideDivForAssign = false;
    } else if (i.isAssignShift == false) {
      this.hideDivForAssign = true;
    }
    const mock = moment(i.date, "YYYY-MM-DD").toLocaleString();
    const another = moment(i.startTime, "YYYY-MM-DD").toLocaleString();
    if (
      moment().isBefore(i.startTime) &&
      !i.isAssignShift &&
      this.buShiftDetails?.cancelShiftPermission && i?.status === 1
    ) {
      this.cancelShiftbtn = true;
    } else {
      this.cancelShiftbtn = false;
    }
    if (moment().isBefore(i.startTime) && i?.status !== 2) {
      this.hideAllButton = true;
      this.adjustShiftbtn = false;
      this.cancelBookedShiftButton = true;

    }
    else {
      this.hideAllButton = false;
      this.adjustShiftbtn = true;
      this.cancelBookedShiftButton = false;

    }
    if (moment().isBefore(i.startTime) && i?.status !== 2 && !i?.isSplitShift) {
      this.hideAllButton = true;

    }
    else {
      this.hideAllButton = false;

    }
    this.ShiftForm?.patchValue({
      buName: this.buId.orgName,
      date: moment(i.date).format('DD MMM YYYY'),
      startTime: moment(i.startTime).format('HH:mm'),
      endTime: moment(i.endTime).format('HH:mm'),
      startDateTime: this.extendedStaffTime,
      endDateTime: this.extendedStaffTimeD,
      staffNeedCount: i.staffNeedCount,
      reqStaffNeedCount: Number(this.dayShifts.find(id => id._id == i._id)?.currentReqShift?.staffNeedCount),
      backUpStaffNeedCount: i.backUpStaffNeedCount,
      backUpStaffNeedCountBooked: i.confirmedStaffs.length,
      backUpStaffNeedCountSBBooked: i.backUpStaffs.length,
    });
    if (i.reportLocationId != null) {
      this.ShiftForm?.patchValue({
        reportLocationId: i.reportLocationId?.name,
      });
    } else {
      this.ShiftForm?.patchValue({
        reportLocationId: i.geoReportingLocation?.name,
      });
    }

    this.isSplitTime = i.isSplitShift;

    if (i.isSplitShift) {
      this.ShiftForm?.patchValue({
        splitstartTime: moment(i.splitShiftStartTime).format('HH:mm'),
        splitendTime: moment(i.splitShiftEndTime).format('HH:mm'),
      });
    }

    if (i.subSkillSets?.length) {
      i.subSkillSets.forEach((element) => {
        this.skillLabel.push([element.skillSetId.name, element.name]);
      });
    } else if (i.mainSkillSets?.length) {
      i.mainSkillSets.forEach((element) => {
        this.skillLabel.push([element.name, '_']);
      });
    }

    if (i.currentReqShift) {
      this.ShiftForm?.patchValue({
        reqStartTime: moment(i.currentReqShift.startTime).format('HH:mm'),
        reqEndTime: moment(i.currentReqShift.endTime).format('HH:mm'),
        reqReportLocationId: i.currentReqShift.reportLocationId?.name,
      });
    }

    this.extShift = [];
    this.extItem = this.ShiftForm?.get('extArr') as FormArray;
    this.extItem?.clear();

    for (var c = 0; i.confirmedStaffs.length > c; c++) {
      const cs = i.confirmedStaffs[c]._id;
      const sd = moment(i.confirmedStaffs[c].startDate, 'DD-MM-YYYY');
      const ed = moment(i.confirmedStaffs[c].endDate, 'DD-MM-YYYY');

      this.extItem?.push(
        this.fb.group({
          ts: i.confirmedStaffs[c].startTime,
          te: i.confirmedStaffs[c].endTime,
          sd: new NgbDate(sd.year(), sd.month() + 1, sd.date()),
          ed: new NgbDate(ed.year(), ed.month() + 1, ed.date()),
          st: '',
          et: '',
        })
      );

      if (i.extendedStaff.length > 0) {
        const inn = i.extendedStaff.findIndex((x) => x.userId == cs);

        if (inn != -1) {
          this.extShift.push([
            i.extendedStaff[inn].confirmStatus,
            i.extendedStaff[inn].userId,
          ]);
        } else {
          this.extShift.push([0, cs]);
        }
      } else {
        this.extShift.push([0, cs]);
      }
    }
  }


  requiredCountChange(value, isShiftChange){
     this.isErrorDisplay = isShiftChange && value > this.ShiftForm.value.backUpStaffNeedCountBooked
  }

  changeOff(i) {
    this.selectedShift = i;
    this.skillsId = [];
    this.skillsLabel = [];
    this.selectedRecall = i;
    this.getLocation();
    this.skillSet = i.confirmedStaffs[0].subSkillSets;

    this.cfr = true;

    this.restOffForm = this.fb.group({
      bu: this.buId.businessUnit,
      date: moment(i.day, 'YYYY-MM-DD').format('DD MMM YYYY'),
      staffN: i.confirmedStaffs[0].name,
      staffId: i.confirmedStaffs[0].staffId,
      startTime: i.confirmedStaffs[0].startTime,
      endTime: i.confirmedStaffs[0].endTime,
      reportLoc: '',
    });

    if (i.isRecalled == true) {
      this.restOffForm.patchValue({
        reportLoc: i.reportLocationId.name,
      });
    }

    this.recallForm = this.fb.group({
      StartTime: null,
      EndTime: null,
      SkillSets: null,
      subSkillSets: null,
      reportLocation: null,
    });
  }

  shiftRecallSubmit() {
    this.shiftRecallReqPayload = {
      shiftDetailId: this.selectedRecall._id,
      startTime:
        moment(this.selectedRecall.day, 'YYYY-MM-DD').format('MM-DD-YYYY') +
        ' ' +
        this.recallForm.controls.StartTime.value +
        ':00 '+ this.gmtOffset,
      endTime:
        moment(this.selectedRecall.day, 'YYYY-MM-DD').format('MM-DD-YYYY') +
        ' ' +
        this.recallForm.controls.EndTime.value +
        ':00 '+ this.gmtOffset,
      reportLocationId: this.recallForm.controls.reportLocation.value,
      mainSkillSets: [],
      subSkillSets: this.skillsId,
      isOffed: this.selectedRecall.isOff,
      isRested: this.selectedRecall.isRest,
      userId: this.selectedRecall.confirmedStaffs[0]._id,
      assignShiftId: this.selectedRecall.draftId._id,
      isSplitShift: false,
      splitStartTime:
        moment(this.selectedRecall.day, 'YYYY-MM-DD').format('MM-DD-YYYY') +
        ' undefined:00 '+ this.gmtOffset,
      splitEndTime:
        moment(this.selectedRecall.day, 'YYYY-MM-DD').format('MM-DD-YYYY') +
        ' undefined:00 '+ this.gmtOffset,
    };

    this.planShiftService
      .createRecall(this.shiftRecallReqPayload)
      .subscribe((res) => {
        this.toastService.success('Recall Request sent successfully.');
        this.ChangeTabId.emit(3);
      });
  }

  setSubSkill(i) {
    this.recallForm.patchValue({
      subSkillSets: null,
    });
    this.subSkill = [];
    this.subSkill.push(i);
  }

  addSkills() {
    this.skillsLabel.push([
      this.recallForm.controls.SkillSets.value.skillSetId.name,
      this.recallForm.controls.subSkillSets.value.name,
    ]);
    this.skillsId.push(this.recallForm.controls.subSkillSets.value._id);
    this.recallForm.patchValue({
      SkillSets: null,
      subSkillSets: null,
    });
  }

  clearLabel(i) {
    this.skillsLabel.splice(i, 1);
    this.skillsId.splice(i, 1);
  }

  onChangeSplit(value) {
    this.isSplit = value;
  }

  getLocation() {
    const location = this.buService
      .getRepoLocations(this.buId)
      .pipe(
        finalize(() => {
          location.unsubscribe();
        })
      )
      .subscribe((res) => {
        this.reportLocation = res['data'].reportingLocation;
      });
  }

  saveConfirmed(i, j) {
    this.toggle = !this.toggle;
    this.status = this.toggle ? 'Enable' : 'Disable';
    this.selectedShift = i;
    this.skillLabel = [];

    const sd =
      this.extItem.value[j].sd.day +
      '-' +
      this.extItem.value[j].sd.month +
      '-' +
      this.extItem.value[j].sd.year;

    const ed =
      this.extItem.value[j].ed.day +
      '-' +
      this.extItem.value[j].ed.month +
      '-' +
      this.extItem.value[j].ed.year;

    this.confirmedShiftForm = {
      from: 'shiftextension',
      userId: i._id,
      shiftDetailsId: this.shiftItem._id,
      startDateTime:
        moment(sd, 'D-M-YYYY').format('MM-DD-YYYY') +
        ' ' +
        this.extItem.value[j].ts +
        ':00 ' + this.gmtOffset,
      endDateTime:
        moment(ed, 'D-M-YYYY').format('MM-DD-YYYY') +
        ' ' +
        this.extItem.value[j].te +
        ':00 ' + this.gmtOffset,
    };

    this.confirmedYesShiftForm = {
      userId: i._id,
      shiftDetailId: this.shiftItem._id,
      startDateTime:
        moment(sd, 'D-M-YYYY').format('MM-DD-YYYY') +
        ' ' +
        this.extItem.value[j].ts +
        ':00 ' + this.gmtOffset,
      endDateTime:
        moment(ed, 'D-M-YYYY').format('MM-DD-YYYY') +
        ' ' +
        this.extItem.value[j].te +
        ':00 ' + this.gmtOffset,
    };

    this.planShiftService
      .shiftExtension(this.confirmedShiftForm)
      .subscribe((res) => {
        this.message = res['message'];
        if (res['limit'] == true) {
          if (i.schemeId.shiftSetup.limits.normalHr.day.alert == true) {
            Swal.fire({
              title: res['message'],
              text: 'Do you want to continue?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              cancelButtonText: 'No',
              confirmButtonText: 'Yes',
            }).then((result) => {
              if (result.isConfirmed) {
                this.planShiftService
                  .confirmedShift(this.confirmedYesShiftForm)
                  .subscribe((res) => {
                    this.toastService.success(
                      'Shift Extension Request sent  successfully.'
                    );
                    this.ChangeTabId.emit(3);
                    this.getSelectedWeek1(this.selectedDay);
                  });
              }
            });
          } else {
            Swal.fire({
              text: 'Exceeds Daily Limit',
              denyButtonText: `Close`,
            });
          }
        } else {
          this.toastService.success(
            'Shift Extension Request sent  successfully.'
          );
          this.ChangeTabId.emit(3);
          this.getSelectedWeek1(this.selectedDay);
        }
      });
  }

  stopShiftExtension(i) {
    const stopShiftParam = {
      userId: i._id,
      shiftDetailId: this.shiftItem._id,
    };

    this.planShiftService
      .stopShiftExtension(stopShiftParam)
      .subscribe((res) => {
        this.toastService.success(
          'Shift Extension Request Stopped successfully.'
        );
        this.ChangeTabId.emit(3);
      });

    this.getSelectedWeek1(this.selectedDay);
  }

  againShiftExt(i, j) {
    const sd =
      this.extItem.value[j].sd.day +
      '-' +
      this.extItem.value[j].sd.month +
      '-' +
      this.extItem.value[j].sd.year;

    const ed =
      this.extItem.value[j].ed.day +
      '-' +
      this.extItem.value[j].ed.month +
      '-' +
      this.extItem.value[j].ed.year;

    const shiftExtParam = {
      from: 'shiftextensionagain',
      userId: i._id,
      shiftDetailsId: this.shiftItem._id,
      startDateTime:
        moment(sd, 'D-M-YYYY').format('MM-DD-YYYY') +
        ' ' +
        this.extItem.value[j].ts +
        ':00 '+ this.gmtOffset,
      endDateTime:
        moment(ed, 'D-M-YYYY').format('MM-DD-YYYY') +
        ' ' +
        this.extItem.value[j].te +
        ':00 '+ this.gmtOffset,
    };

    const againShiftExtParam = {
      endDateTime:
        moment(ed, 'D-M-YYYY').format('MM-DD-YYYY') +
        ' ' +
        this.extItem.value[j].te +
        ':00 '+ this.gmtOffset,
      shiftDetailId: this.shiftItem._id,
      startDateTime:
        moment(sd, 'D-M-YYYY').format('MM-DD-YYYY') +
        ' ' +
        this.extItem.value[j].ts +
        ':00 '+ this.gmtOffset,
      userId: i._id,
    };

    this.planShiftService.shiftExtension(shiftExtParam).subscribe((res) => {
      this.message = res['message'];
      if (res['limit'] == true) {
        if (i.schemeId.shiftSetup.limits.normalHr.day.alert == true) {
          Swal.fire({
            title: res['message'],
            text: 'Do you want to continue?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
          }).then((result) => {
            if (result.isConfirmed) {
              this.planShiftService
                .againShiftExtension(againShiftExtParam)
                .subscribe((res) => {
                  this.toastService.success(
                    'Shift Extension Request sent successfully.'
                  );
                  this.ChangeTabId.emit(3);
                });
              this.getSelectedWeek1(this.selectedDay);
            }
          });
        } else {
          Swal.fire({
            text: 'Exceeds Daily Limit',
            denyButtonText: `Close`,
          });
        }
      } else {
        this.toastService.success('Shift Extension Request sent successfully.');
        this.ChangeTabId.emit(3);
        this.getSelectedWeek1(this.selectedDay);
      }
    });
  }

  saveAdjust() {
    this.ShiftDetIdAdjust = this.shiftItem._id;

    this.adjustShiftForm = {
      businessUnitId: this.buId._id,
      shiftDetailsId: this.ShiftDetIdAdjust,
      startDate: this.weekstartDay,
      staffNeedCount: parseInt(this.ShiftForm?.controls?.adjust.value),
    };

    this.planShiftService
      .saveAdjustShift(this.adjustShiftForm)
      .subscribe((res) => {
        this.toastService.success('Staff Count adjusted successfully.');
        this.ChangeTabId.emit(3);
      });
  }

  requestShiftChange(i) {
    if (this.isErrorDisplay) {
      return
    }
    if (!this.ShiftForm.value.reqReportLocationId) {
      return this.toastService.error("Please enter Reporting Location")
    }
    this.selectedShift = i;
    this.skillLabel = [];
    this.shiftItem = i;

    this.requestShiftForm = {
      shiftId: this.shiftReqId,
      shiftDetailsId: this.shiftItem._id,
      startTime:
        this.reqstartdate +
        ' ' +
        this.ShiftForm?.controls?.reqStartTime.value +
        ':00 '+ this.gmtOffset,
      endTime:
        this.reqEnddate +
        ' ' +
        this.ShiftForm?.controls?.reqEndTime.value +
        ':00 '+ this.gmtOffset,
      reportLocationId: this.ShiftForm?.controls?.reqReportLocationId?.value?._id,
      staffNeedCount: parseInt(this.ShiftForm?.controls?.reqStaffNeedCount?.value),
    };

    this.planShiftService
      .requestChange(this.requestShiftForm)
      .subscribe((res) => {
        this.toastService.success('Shift Change Request sent succesfully.');
        this.ChangeTabId.emit(3);
      },(error) => {
        this.toastService.error("Staff need Count Should be greater than 0.")
      });
  }

  stopRequestShiftChange(i) {
    this.selectedShift = i;
    this.skillLabel = [];
    this.shiftItem = i;

    this.stopRequestShiftForm = {
      shiftId: this.shiftItem.currentReqShift.shiftId,
      shiftDetailsId: this.shiftItem._id,
    };

    this.planShiftService
      .stopRequestChange(this.stopRequestShiftForm)
      .subscribe((res) => {
        this.toastService.success('Shift Change Request stopped  succesfully.');
        this.ChangeTabId.emit(3);
      });
  }

  cancelSingleShift() {
    this.cancelShiftForm = {
      shiftId: this.shiftReqId,
      shiftDetailsId: this.shiftItem._id,
    };
    this.selectedShift.isSplitShift ? this.cancelShiftForm['isSplitShift'] = true : delete this.cancelShiftForm.isSplitShift
    if (this.cancelPermission == false) {
      this.toastService.warning('Permission Denied to cancel');
    } else {
      Swal.fire({
        title: 'Cancel Confirmation',
        text: 'Do you want to cancel shift ?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          this.planShiftService.cancelShiftNew(this.cancelShiftForm).subscribe(
            (result) => {
              this.ChangeTabId.emit(3);
              this.toastService.success('Cancelled Shift succesfully ');
            },
            (error) => {
              this.toastService.warning(result['message']);
              this.errors = error;
            }
          );
        }
      });
    }
  }

  cancelBookedShift(data) {
    const cancelShiftForm = {
      shiftId: this.shiftReqId,
      shiftDetailsId: this.shiftItem._id,
      userId: data?._id,
      splitShiftId: this.shiftItem?.splitShiftId
    };

    if (this.cancelPermission == false) {
      this.toastService.warning('Permission Denied to cancel');
    } else {
      Swal.fire({
        title: 'Cancel Confirmation',
        text: "Are you sure you want to cancel this user's booked shift?",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          this.planShiftService.cancelBookedShift(cancelShiftForm).subscribe(
            (result) => {
              this.ChangeTabId.emit(3);
              this.toastService.success('Cancelled Shift succesfully ');
            },
            (error) => {
              this.toastService.warning(error?.error?.message);
              this.errors = error;
            }
          );
        }
      });
    }
  }
}
