import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { getGmtOffSet } from 'src/app/core/utils/helper';
import { ShiftSchedulerService } from 'src/app/users/service/operations/shift-scheduler.service';

@Component({
  selector: 'app-plan-overview',
  templateUrl: './plan-overview.component.html',
  styleUrls: ['./plan-overview.component.scss'],
})
export class PlanOverviewComponent implements OnInit {
  isHour: boolean = true;
  @Input() isPlan: boolean;
  @Input() bu;
  @Input() shift;
  @Input() page;
  @Output() overviewHeaderData = new EventEmitter<any>();

  startDay: string;
  currWeek: any = null;
  buWeekArr = [];
  weekCount = 0;
  currentWeekCount: any;
  stafflisting: any;
  endDay: string;
  shiftReadReq: any;
  shiftRead: Object;
  dashboardData: any;
  graphData: any;
  rawGraph = [];

  graphNeed = [];
  graphNeedAssign = [];
  graphBooked = [];

  graphHrNeedAssign = [];
  graphHrNeed = [];
  graphHrBooked = [];
  hrAndShiftChartData: any;
  headerData: any;

  bookShiftArr = [];
  openShiftArr = [];
  assignShiftArr = [];

  yAxesLabel = [];

  StartDate: moment.Moment;

  dayArr = [
    { count: 0, d: 'M', day: 'monday' },
    { count: 1, d: 'T', day: 'tuesday' },
    { count: 2, d: 'W', day: 'wednesday' },
    { count: 3, d: 'T', day: 'thursday' },
    { count: 4, d: 'F', day: 'friday' },
    { count: 5, d: 'S', day: 'saturday' },
    { count: 6, d: 'S', day: 'sunday' },
  ];
  selectedDay: any;
  graphLoader: boolean = true;
  index: any = 2;
  gmtOffset: any;
  constructor(private planShift: ShiftSchedulerService) {}

  ngOnInit(): void {
    this.gmtOffset = getGmtOffSet();
    this.getWeeks();
  }

  getShiftGraphData() {
    this.graphLoader = true;
    // this.headerData = {
    //   one: 0,
    //   two: 0,
    //   three: 0,
    //   four: 0,
    //   five: 0,
    //   six: 0,
    // };

    // this.overviewHeaderData.emit(this.headerData);

    this.shiftReadReq = {
      businessUnitId: this.bu._id,
      startDate: this.startDay,
      from: 'plan',
    };

    this.planShift
      .getShiftGraph(this.shiftReadReq)
      .pipe(
        finalize(() => {
          this.graphLoader = false;
        })
      )
      .subscribe((res) => {
        if (this.page == '2') {
          this.shiftRead = this.shift;
        } else {
          this.shiftRead = res['data'];
          this.headerData = {
            one: res['data']['dashboardGraphData'].plannedFlexiHours,
            two: res['data']['dashboardGraphData'].plannedFlexiShifts,
            three: res['data']['dashboardGraphData'].bookedFlexiHours,
            four: res['data']['dashboardGraphData'].bookedFlexiShifts,
            five: res['data']['dashboardGraphData'].assignFlexiHours,
            six: res['data']['dashboardGraphData'].assignFlexiShifts,
          };
          this.overviewHeaderData.emit(this.headerData);
        }

        this.getGraphData();
        this.dayViewGraph(this.dayArr[0]);
      });
  }

  getGraphData() {
    this.graphNeed = [];
    this.graphNeedAssign = [];
    this.graphBooked = [];
    this.graphHrNeed = [];
    this.graphHrNeedAssign = [];
    this.graphHrBooked = [];

    this.rawGraph = this.shiftRead['graphDataWeb'];
    const weekdays = [];
    moment.updateLocale('en', {
      week: {
        dow: 1, // Monday is the first day of the week.
      },
    });
    for (var i = 0; i < 7; i++) {
      weekdays.push(moment(this.StartDate).weekday(i).format('DD-MM-YYYY'));
    }
    const graphRawData = Object.keys(this.rawGraph).reverse();
    for (let prop in weekdays) {
      if (graphRawData.includes(weekdays[prop])) {
        this.graphNeed.push(
          this.rawGraph[weekdays[prop]]['numberOfShifts'].need
        );
        this.graphNeedAssign.push(
          this.rawGraph[weekdays[prop]]['numberOfShifts'].needAssign
        );
        this.graphBooked.push(
          this.rawGraph[weekdays[prop]]['numberOfShifts'].booked
        );

        this.graphHrNeed.push(this.rawGraph[weekdays[prop]]['totalHours'].need);
        this.graphHrNeedAssign.push(
          this.rawGraph[weekdays[prop]]['totalHours'].needAssign
        );
        this.graphHrBooked.push(
          this.rawGraph[weekdays[prop]]['totalHours'].booked
        );
      } else {
        this.graphNeed.push(0);
        this.graphNeedAssign.push(0);
        this.graphBooked.push(0);

        this.graphHrNeed.push(0);
        this.graphHrNeedAssign.push(0);
        this.graphHrBooked.push(0);
      }
    }

    this.changeHrAndNumber(true);
  }

  getWeeks() {
    this.planShift.getWeeks(this.bu._id).subscribe((res) => {
      let getDate = moment().startOf('isoWeek').format("MM-DD-YYYY");
      const count = res['data'].noOfWeek
      this.currentWeekCount = count;
      if (count > 0) {
        for (var i = -count; i <= count; i++) {
          let dummyData = this.getWeekStartEndDate(getDate, 7 * (i))
          this.buWeekArr.push(dummyData);
          //localStorageService.set("WorkWeekList",  $scope.myWeekRange);
        }
      }
      //this.buWeekArr = res['data'].noOfWeek;
      this.changeWeek('');
    });
  }

  getWeekStartEndDate = function (date, count) {
    let datesToReturn = {};
    let label = moment().startOf('isoWeek').add(count, 'days').format("MMM DD YYYY") + ' to ' + moment().startOf('isoWeek').add(count + 6, 'days').format("MMM DD YYYY")
    let value = moment().startOf('isoWeek').add(count, 'days').format("MM-DD-YYYY");

    datesToReturn = { label: label, value: value, key: count };
   /* if (count == 0 && $scope.workWeeksDate == undefined) {

      $scope.workWeeksDate = value;

    }*/
    return label;
  }

  changeWeek(str) {
    if (str == 'Nxt') {
      this.weekCount =
        this.weekCount < this.currentWeekCount
          ? this.weekCount + 1
          : this.currentWeekCount;
    } else if (str == 'Prev') {
      this.weekCount =
        this.weekCount == -Math.abs(this.currentWeekCount)
          ? this.weekCount
          : this.weekCount - 1;
    } else {
      this.weekCount = 0;
    }

    this.currWeek =
      this.buWeekArr[this.currentWeekCount + this.weekCount];


    let startDaystr = this.currWeek.slice(0, 11);    
    let date = moment(startDaystr).format('MM-DD-YYYY');
    this.StartDate = moment(startDaystr);
    this.startDay = date + ` 00:00:00 ${this.gmtOffset}`;

    let endDayString = moment(startDaystr)
      .add(6, 'days')
      .format('MM-DD-YYYY');
    this.endDay = endDayString + ` 00:00:00 ${this.gmtOffset}`;
    this.getShiftGraphData();
  }

  changeHrAndNumber(value) {
    this.isHour = value;
    if (value === true) {
      this.hrAndShiftChartData = [
        {
          data: this.graphHrNeedAssign,
        },
        {
          data: this.graphHrNeed,
        },
        {
          data: this.graphHrBooked,
        },
      ];
      this.index = 2;
    } else {
      this.hrAndShiftChartData = [
        {
          data: this.graphNeedAssign,
        },
        {
          data: this.graphNeed,
        },
        {
          data: this.graphBooked,
        },
      ];
      this.index = 1;
    }
    this.dayViewGraph(this.dayArr[0]);
  }

  hrAndShiftChartoptions = {
    responsive: true, // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
    scales: {
      xAxes: [
        {
          maxBarThickness: 40,
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };

  hrAndShiftChartLabels = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  // CHART COLOR.
  hrAndShiftChartColors = [
    { backgroundColor: '#0000FF' },
    { backgroundColor: '#a42138' },
    { backgroundColor: '#6BB11E' },
  ];

  // Day View Chart
  dayViewGraph(i) {
    this.bookShiftArr = [];
    this.openShiftArr = [];
    this.assignShiftArr = [];

    this.yAxesLabel = [];

    this.selectedDay = i;
    const weeklyStaffGraphData = this.shiftRead['weeklyStaffGraphData'];

    const bookShift = weeklyStaffGraphData.staffAppliedData[i.day];
    for (let i in bookShift) {
      this.bookShiftArr.push(bookShift[i][this.index]);
      this.yAxesLabel.push(moment(bookShift[i][0]).format('HH:mm'));
    }

    const openShift = weeklyStaffGraphData.staffNeedData[i.day];
    for (let i in openShift) {
      this.openShiftArr.push(openShift[i][this.index]);
    }

    const assignShift = weeklyStaffGraphData.staffNeedDataAssing[i.day];
    for (let i in assignShift) {
      this.assignShiftArr.push(assignShift[i][this.index]);
    }

    this.dayChartData = [
      {
        label: 'Booked Open Shift',
        data: this.bookShiftArr,
        fill: false,
        borderWidth: 3,
        lineTension: 0,
        pointStyle: 'star',
      },
      {
        label: 'Planned Open Shift',
        data: this.openShiftArr,
        fill: false,
        borderWidth: 3,
        lineTension: 0,
        pointStyle: 'rect',
      },
      {
        label: 'Assign Shift',
        data: this.assignShiftArr,
        fill: false,
        borderWidth: 3,
        lineTension: 0,
        pointStyle: 'circle',
      },
    ];
  }

  dayChartData = [
    {
      label: 'Booked Open Shift',
      data: this.bookShiftArr,
      fill: false,
      borderWidth: 3,
      lineTension: 0,
      pointStyle: 'star',
    },
    {
      label: 'Planned Open Shift',
      data: this.openShiftArr,
      fill: false,
      borderWidth: 3,
      lineTension: 0,
      pointStyle: 'rect',
    },
    {
      label: 'Assign Shift',
      data: this.assignShiftArr,
      fill: false,
      borderWidth: 3,
      lineTension: 0,
      pointStyle: 'circle',
    },
  ];

  dayChartOptions = {
    responsive: true,
    borderColor: 'blue',
    scales: {
      xAxes: [
        {
          ticks: {
            callback: function (value, index, values) {
              return index % 4 == 0 ? value : '';
            },
            color: 'red',
            maxRotation: 0,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            borderDash: [4, 4],
          },
        },
      ],
    },
  };

  dayChartColorOption = [
    { borderColor: '#6BB11E', backgroundColor: '#6BB11E' },
    { borderColor: '#a42138', backgroundColor: '#a42138' },
    { borderColor: '#0000FF', backgroundColor: '#0000FF' },
  ];
}
